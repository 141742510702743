<template>
  <WheelTyreManagerStyled> manager </WheelTyreManagerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const WheelTyreManagerStyled = styled('div')``

export default {
  components: {
    WheelTyreManagerStyled,
  },
}
</script>
